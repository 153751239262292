<template>
  <div class="documBox fadeIn">
    <el-input placeholder="输入关键字进行过滤" v-model="filterText"> </el-input>
    <el-tree
      class="filter-tree"
      :data="nameList"
      :filter-node-method="filterNode"
      :default-expanded-keys="expanded"
      :check-strictly="true"
      :highlight-current="true"
      node-key="id"
      accordion
      show-checkbox
      ref="tree"
      @node-click="checkItem"
    >
    </el-tree>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
let that;
export default {
  data() {
    return {
      checkArr: [],
      filterText: "",
      nameList: [],
      workId: "",
      expanded:[],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    //   选中文档节点
    checkItem(data) {
      if (data.children.length == 0) {
        // window.localStorage.setItem("workId",data.id)
        that.$router.push(
          `/Documentation?workId=${data.id}&title=${data.label}`
        );
      }
    },
    //   搜索
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 获取菜单列表
    getALLWorkOrderLists() {
      that.$api.workOrder.getALLWorkOrderLists().then((res) => {
        if (res.code == 200) {
          that.checkArr = [];
          let arr = [];
          res.data.forEach((element) => {
            arr.push(that.setTreeList(element));
          });
          that.expanded=[res.data[0].workId + "-" + res.data[0].workType]
          that.nameList = arr;
          setTimeout(() => {
            this.$refs.tree.setCheckedKeys(that.checkArr);
          }, 300);

          // if (window.localStorage.getItem("workId")) {
          //      this.$refs.tree.setCheckedKeys([window.localStorage.getItem("workId")]);
          //      window.localStorage.setItem("workId",null)
          // }
        }
      });
    },
    setTreeList(data) {
      let obj = {
        id: data.workId + "-" + data.workType,
        label: data.name,
      };

      if (data.workType == 1) {
        that.checkArr.push(obj.id);
      }
      let arr = [];
      if (data.workType != 1) {
        data.children.forEach((element) => {
          arr.push(that.setTreeList(element));
        });
        obj["children"] = arr;
      } else {
        obj["children"] = [];
      }
      return obj;
    },
  
 
  },
  
  mounted() {
    that = this;
    // document.title = "说明文档";
    that.getALLWorkOrderLists();
     wx.ready(function () {
  
      //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: "说明文档", // 分享标题
        desc: "说明文档", // 分享描述
        link: window.location.href, // 分享链接
        imgUrl: "https://oss.cniot.fun/factory-platform/test/image/platform/20220316141425508.jpg", // 分享图标
        success: function () {
          // 设置成功

        },
      });

      wx.updateTimelineShareData({
       title: "说明文档", // 分享标题
        desc: "说明文档", // 分享描述
        link: window.location.href, // 分享链接
        imgUrl: "https://oss.cniot.fun/factory-platform/test/image/platform/20220316141425508.jpg", // 分享图标
        success: function () {
          // 设置成功

        },
      });
     
    });
  },
};
</script>
<style scoped>
.documBox {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 5vw;
}
::v-deep .el-checkbox {
  display: none;
}
::v-deep .is-checked {
  color: #409eff;
}
::v-deep .el-tree-node__label {
  font-size: 16px;
}
::v-deep .el-tree-node {
  padding: 5px 0;
}
</style>